import { DataService } from "../config/dataService/dataService";

const getById = async (_id) => {
  try {
    const response = await DataService.get(
      `${process.env.REACT_APP_BASE_URL_RTTE}/member/${_id}`
    );
    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

const edit = async (_id, _val) => {
  try {
    const response = await DataService.patch(
      `${process.env.REACT_APP_BASE_URL_RTTE}/member/${_id}`,
      { ..._val }
    );
    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

const changePass = async (_oldPassword, _newPassword) => {
  try {
    const response = await DataService.post(
      `${process.env.REACT_APP_BASE_URL_RTTE}/auth/change-password`,
      { oldPassword: _oldPassword, newPassword: _newPassword }
    );
    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

const createCodBank = async (_id, _options) => {
  try {
    const response = await DataService.post(
      `${process.env.REACT_APP_BASE_URL_RTTE}/cod-bank`,
      {
        accountName: _options.accountName,
        accountNumber: _options.accountNumber,
        bank: _options.bank,
        copyOfIdCard: _options.copyOfIdCard,
        copyOfBankAccount: _options.copyOfBankAccount,
        member: parseInt(_id),
        isDefault: _options?.isDefault || "false",
      }
    );
    if (response.status === 201) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

const patchCodBank = async (_id, _codBankId, _options) => {
  try {
    const response = await DataService.patch(
      `${process.env.REACT_APP_BASE_URL_RTTE}/cod-bank/${_codBankId}`,
      {
        accountName: _options.accountName,
        accountNumber: _options.accountNumber,
        bank: _options.bank,
        copyOfIdCard: _options.copyOfIdCard,
        copyOfBankAccount: _options.copyOfBankAccount,
        member: parseInt(_id),
        isDefault: _options.isDefault,
      }
    );
    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

const deleteCodBank = async (id) => {
  try {
    const response = await DataService.delete(
      `${process.env.REACT_APP_BASE_URL_RTTE}/cod-bank/${id}`
    );

    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    return { status: false, err: err };
  }
};

export {
  getById,
  edit,
  changePass,
  createCodBank,
  patchCodBank,
  deleteCodBank,
};
