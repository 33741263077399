const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  REGIS_BEGIN: 'REGIS_BEGIN',
  REGIS_SUCCESS: 'REGIS_SUCCESS',
  REGIS_ERR: 'REGIS_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  RESET_DEFAULT: 'RESET_DEFAULT',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  regisBegin: () => {
    return {
      type: actions.REGIS_BEGIN,
    };
  },

  regisSuccess: (data) => {
    return {
      type: actions.REGIS_SUCCESS,
      data,
    };
  },

  regisErr: (err) => {
    return {
      type: actions.REGIS_ERR,
      err,
    };
  },

  resetDefault: () => {
    return {
      type: actions.RESET_DEFAULT,
    };
  },
  
};

export default actions;
