import actions from "./action";

const initialState = {
  profile: null,
};

const ProfileReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case actions.SET_PROFILE:
      return {
        ...state,
        profile: data,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
