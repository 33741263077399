import UilSetting from "@iconscout/react-unicons/icons/uil-setting";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import Cookies from "js-cookie";
import { logOut } from "../../../redux/authentication/actionCreator";
import { getById } from "../../../servers/user";
import { setGlobalProfile } from "../../../redux/profile/actionCreator";

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => {
    return {
      ...state.profile,
    };
  });
  const [state, setState] = useState({
    flag: "en",
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const getUsername = async () => {
    const resp = await getById(Cookies.get("user"));
    if (resp.status) {
      dispatch(setGlobalProfile(resp.data));
    }
  };

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate("/")));
  };

  useEffect(() => {
    getUsername();
  }, []);

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/* <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">Abdullah Bin Talha</Heading>
            <p>UI Expert</p>
          </figcaption>
        </figure> */}
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilSetting /> Settings
            </Link>
          </li>
        </ul>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle("en", e)} to="#">
        <img src={require("../../../static/img/flag/en.png")} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle("esp", e)} to="#">
        <img src={require("../../../static/img/flag/esp.png")} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle("ar", e)} to="#">
        <img src={require("../../../static/img/flag/ar.png")} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Search /> */}
      {/* <Message /> */}
      {/* <Notification /> */}
      {/* <Settings /> */}
      {/* <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}
      {/* <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <h2 className="ninjadash-nav-actions__author--name">บริการ</h2>
            <UilAngleDown />
          </Link>
        </Popover>
      </div> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Space>
          <font style={{ cursor: "pointer" }}>
            <b>
              <u>{profile?.fullname}</u>
            </b>
          </font>
          {" | "}
          <Link
            className="user-dropdwon__bottomAction"
            onClick={SignOut}
            to="#"
          >
            <font style={{ cursor: "pointer", color: "#666D92" }}>
              <b>ออกจากระบบ</b>
            </font>
          </Link>
        </Space>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
