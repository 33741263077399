const actions = {
  SET_PROFILE: "SET_PROFILE",

  setProfile: (data) => {
    return {
      type: actions.SET_PROFILE,
      data,
    };
  },
};

export default actions;
