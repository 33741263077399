import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, regisBegin, regisSuccess, regisErr, resetDefault } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post(`${process.env.REACT_APP_BASE_URL_RTTE}/auth/login/`, {...values, role: 2});
      if (response.status === 200) {
        Cookies.set('access_token', response.data.token);
        Cookies.set('role', response.data.role.id);
        Cookies.set('user', response.data.id);
        Cookies.set('fullname', response.data.fullname);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(true));
        callback();
      } else {
        dispatch(loginErr(response));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(regisBegin());
    try {
      const response = await DataService.post(`${process.env.REACT_APP_BASE_URL_RTTE}/register`, {...values, username: values.telno, role: 2, memberType: 1});
      if (response.status === 201) {
        dispatch(regisSuccess(true));
        callback();
      } else {
        dispatch(regisErr('Registration failed!'));
      }
    } catch (err) {
      dispatch(regisErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const resetDefaultState = () => {
  return async (dispatch) => {
    dispatch(resetDefault())
  }
}

export { login, logOut, register, resetDefaultState };
