import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const BlankPage = lazy(() => import("../../container/pages/BlankPage"));
const ProfilePage = lazy(() => import("../../container/pages/ProfilePage"));
const AddressPage = lazy(() => import("../../container/pages/Address"));
const ShippingPage = lazy(() => import("../../container/pages/Shipping"));
const CodPage = lazy(() => import("../../container/pages/Cod"));
const ShippingOne = lazy(() => import("../../container/pages/ShippingOne"));
const ShippingMulti = lazy(() => import("../../container/pages/ShippingMulti"));

function PagesRoute() {
  return (
    <Routes>
      <Route index element={<Navigate to="/admin/pages/profile" />} />
      <Route path="starter" element={<BlankPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="address" element={<AddressPage />} />
      <Route path="shipping" element={<ShippingPage />} />
      <Route path="shipping-one" element={<ShippingOne />} />
      <Route path="shipping-multi" element={<ShippingMulti />} />
      <Route path="cod" element={<CodPage />} />
      <Route path="*" element={<Navigate to="/admin/pages/profile" />} />
    </Routes>
  );
}

export default PagesRoute;
