/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import { Button, Col, Layout, Row, Dropdown } from "antd";
import propTypes from "prop-types";
import { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import MenueItems from "./MenueItems";

import { UilAngleDown } from "@iconscout/react-unicons";

import {
  FooterStyle,
  LayoutContainer,
  SmallScreenAuthInfo,
  TopMenuSearch,
} from "./Style";
import TopMenu from "./TopMenu";
import AuthInfo from "../components/utilities/auth-info/info";

const { theme } = require("../config/theme/themeVariables");

const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        customizerAction: false,
        activeSearch: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { layoutMode, rtl, topMenu } = this.props;

      const left = !rtl ? "left" : "right";
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const SideBarStyle = {
        margin: "63px 0 0 0",
        padding: `${!rtl ? "20px 20px 55px 0" : "20px 0 55px 20px"}`,
        overflowY: "auto",
        height: "100vh",
        position: "fixed",
        [left]: 0,
        zIndex: 988,
      };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: "auto",
          [rtl ? "marginLeft" : "marginRight"]: "-17px",
        };
        return <div style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
          [left]: "2px",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const items = [
        {
          key: 'ShippingOne',
          label: (
            <Link to="/admin/pages/shipping-one">ส่งพัสดุชิ้นเดียว</Link>
          ),
        },
        {
          key: 'ShippingMulti',
          label: (
            <Link to="/admin/pages/shipping-multi">ส่งพัสดุมากกว่าหนึ่งชิ้น</Link>
          ),
        }
      ];

      return (
        <LayoutContainer>
          <Layout className="layout">
            <Header
              style={{
                position: "fixed",
                width: "100%",
                top: 0,
                [!rtl ? "left" : "right"]: 0,
              }}
            >
              <div className="ninjadash-header-content d-flex">
                <div className="ninjadash-header-content__left">
                  <div className="navbar-brand align-cener-v">
                    <Link
                      className={
                        topMenu && window.innerWidth > 991
                          ? "ninjadash-logo top-menu"
                          : "ninjadash-logo"
                      }
                      to="/admin"
                    >
                      <img
                        src={
                          layoutMode === "lightMode"
                            ? require(`../static/img/logo-rtte.svg`).default
                            : require(`../static/img/logo-rtte.svg`).default
                        }
                        alt=""
                        style={{
                          minWidth: window.innerWidth > 375 ? "13rem" : "9rem",
                          padding: "0.2rem 1rem 0.2rem 0rem",
                        }}
                      />
                    </Link>
                    {!topMenu || window.innerWidth <= 991 ? (
                      <Button type="link" onClick={toggleCollapsed}>
                        <img
                          src={require(`../static/img/icon/${
                            collapsed ? "left-bar.svg" : "left-bar.svg"
                          }`)}
                          alt="menu"
                        />
                      </Button>
                    ) : null}
                  </div>
                </div>
                <div
                  className="ninjadash-header-content__right d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 991 ? <TopMenu /> : null}
                    <Dropdown menu={{ items }} placement="bottomLeft">
                      <Button
                        size="default"
                        type="white"
                        style={{
                          borderColor: "#fff",
                          boxShadow: "unset",
                          display: "inline-flex",
                          padding: "0",
                          alignItems: "end",
                        }}
                      >
                        บริการ
                        <UilAngleDown
                          color="#00237C"
                          style={{ margin: "6px 0 0 0" }}
                        />
                      </Button>
                    </Dropdown>
                  </div>
                  <div className="ninjadash-nav-actions">
                    {topMenu && window.innerWidth > 991 ? (
                      <TopMenuSearch>
                        <div className="top-right-wrap d-flex">
                          <span />
                          <AuthInfo />
                        </div>
                      </TopMenuSearch>
                    ) : (
                      <AuthInfo />
                    )}
                  </div>
                </div>
                <div className="ninjadash-header-content__mobile">
                  <div className="ninjadash-mobile-action">
                    <Link className="btn-auth" onClick={onShowHide} to="#">
                      <UilEllipsisV />
                    </Link>
                  </div>
                </div>
              </div>
            </Header>
            <div className="ninjadash-header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="ninjadash-header-more-inner">
                    <SmallScreenAuthInfo hide={hide}>
                      <AuthInfo rtl={rtl} />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={theme}>
                  <Sider
                    width={280}
                    style={SideBarStyle}
                    collapsed={collapsed}
                    theme={layoutMode === "lightMode" ? "light" : "dark"}
                  >
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={(props) => (
                        <div {...props} className="ninjadash-track-vertical" />
                      )}
                    >
                      <MenueItems
                        topMenu={topMenu}
                        toggleCollapsed={toggleCollapsedMobile}
                      />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                  <FooterStyle className="admin-footer">
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">
                          © {new Date().getFullYear()}
                          <Link to="#">RTT Express</Link>
                        </span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          {/* <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink> */}
                        </div>
                      </Col>
                    </Row>
                  </FooterStyle>
                </Content>
              </Layout>
            </Layout>
          </Layout>
          {window.innerWidth <= 991 ? (
            <span
              className={collapsed ? "ninjadash-shade" : "ninjadash-shade show"}
              onClick={toggleCollapsed}
            />
          ) : (
            ""
          )}
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
