import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';
import { NavLink, useLocation } from 'react-router-dom'

const AuthLayout = (WraperContent) => {
  // const location = useLocation();
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {/* <AuthenticationWrap style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")` }}> */}
        <WraperContent />
      </Suspense>
    );
  };
};

export default AuthLayout;
